import React, { useState, useEffect } from "react";
import './App.css';
import JsonData from "./data/data.json";
import Navbar from './components/Navbar';
import About from './components/About';
import Services from './components/Services';
import Teams from './components/Teams';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div className="App">
      <Navbar />
      <About />
      <Services data={landingPageData.Services}/>
      <Teams/>
      <Contact />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
