import React, { useState } from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.mobileMenu} onClick={toggleMenu}>
        <div className={isMenuOpen ? styles.bartop_open : styles.bartop} />
        <div className={isMenuOpen ? styles.barmiddle_open : styles.barmiddle} />
        <div className={isMenuOpen ? styles.barbottom_open : styles.barbottom} />
      </div>
      <ul className={isMenuOpen? styles.navbarlinks : styles.navbarlinks_close}>
        <li><a href="#features">Features</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#team">Team</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
  );
};
export default Navbar;