import React from 'react'

function About() {
  return (
    <div>
      <h1>NiramSoft</h1>
      <h3>A tech company with a blend of design and innovation</h3>
      <h1>Let us build something</h1>
    </div>
  )
}

export default About